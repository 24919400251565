import React, {useContext, useEffect} from 'react'
import debit from '../../../../../assets/images/card-types/debit.svg'
import visa from '../../../../../assets/images/card-types/visa.svg'
import mastercard from '../../../../../assets/images/card-types/mastercard.svg'
import amex from '../../../../../assets/images/card-types/amex.svg'
import unionPay from '../../../../../assets/images/card-types/union-pay.svg'
import PropTypes from 'prop-types'
import {StringUtil} from '../../../StringUtil'
import {PaymentAmount, ValidatablePaymentAmount} from '../../PaymentAmount'
import {AppStateContext} from '../../../../App.state'
import {PaymentFormStateValidator} from '../../../PaymentFormStateValidator'
import {SET_CARD_AMOUNT} from '../Actions'

const cardConfigValues = [
  {name: 'debit', surchargePercentage: 0, image: debit, excludeFor: 'SBTE_DOE_SF_APP,STRL_DOE_SF_APP'},
  {name: 'visa', surchargePercentage: 0.44, image: visa},
  {name: 'mastercard', surchargePercentage: 0.44, image: mastercard},
  {name: 'amex', surchargePercentage: 1.54, image: amex, excludeFor: 'SBTE_DOE_SF_APP,STRL_DOE_SF_APP'},
  {name: 'unionPay', surchargePercentage: 1.76, image: unionPay, excludeFor: 'SBTE_DOE_SF_APP,STRL_DOE_SF_APP'},
]

function surchargeAmount(surchargePercentage, amount) {
  const surchargeAmount = Number(amount / 100 * surchargePercentage)
  return StringUtil.formattedAmount(surchargeAmount)
}

function allCards(amount, agencyCode) {
  return cardConfigValues
    .filter(cardType => !cardType.excludeFor?.includes(agencyCode))
    .map((cardType) => (
      <div id={cardType.name} key={cardType.name}
         className='card-type-container flex flex-col w-1/6 m-1 p-3 bg-snsw-gray-8 rounded-lg'>
        <img src={cardType.image} alt={cardType.name}/>
        <div className='surcharge-percentage mt-3 text-xs text-center'>
          {cardType.surchargePercentage}%
        </div>
        <div className='surcharge-amount mt-1 font-bold text-sm text-center'>
          {surchargeAmount(cardType.surchargePercentage, amount)}
        </div>
      </div>
    ))
}

export function Card(props) {
  const {appState, dispatch} = useContext(AppStateContext)
  const paymentFormStateValidator = PaymentFormStateValidator('CARD')

  useEffect(() => {
    amountInputChanged(props.amount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function amountInputChanged(newAmount) {
    dispatch({type: SET_CARD_AMOUNT, value: newAmount})
  }

  const amountErrorMessage = paymentFormStateValidator.validateField('amount', appState.cardPayment).errorMessage

  function getInitialAmount() {
    const cardPaymentAmountInAppState = appState.cardPayment && appState.cardPayment.amount
    return cardPaymentAmountInAppState || 0
  }

  return (
    <div id='card-section' data-testid='card-section' className='border-4 border-snsw-gray-7 m-2 mt-6'>
      {
        !props.splitPaymentEnabled &&
          <PaymentAmount
            data-testid='card-amount-disabled'
            label='Amount to be charged'
            amount={getInitialAmount()}
            fieldDisabled={true}/>
      }
      {
        props.splitPaymentEnabled &&
          <ValidatablePaymentAmount
              id='card-amount'
              label='Amount to be charged'
              amount={props.amount}
              amountChange={amountInputChanged}
              errorId='card-amount-validation-error'
              errorMessage={props.amount > 0 ? amountErrorMessage : ''}
              roundingDisabled={true}
              fieldDisabled={props.amount <= 0}
          />
      }
      <div className='flex flex-row m-6 justify-center'>
        {allCards(props.amount, props.agencyCode)}
      </div>
    </div>
  )
}

Card.propTypes = {
  amount: PropTypes.number,
  splitPaymentEnabled: PropTypes.bool.isRequired,
  agencyCode: PropTypes.string,
}
